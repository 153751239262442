<template>
  <div class="pa-3">
    <h3>Integrasi BCA</h3>
    <v-card
      class="mt-6 pa-3 d-flex justify-center align-center"
      outlined
      height="400px"
    >
      Coming Soon 🚀
    </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
