import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{attrs:{"loading":_vm.loadingPlatform,"min-height":"80vh"}},[_c('div',{staticClass:"d-flex pa-5"},[_c(VNavigationDrawer,{staticClass:"left-sidebar rounded",attrs:{"width":"280","touchless":"","mobile-breakpoint":"sm","temporary":_vm.$vuetify.breakpoint.xsOnly,"absolute":""},model:{value:(_vm.isLeftSidebarOpen),callback:function ($$v) {_vm.isLeftSidebarOpen=$$v},expression:"isLeftSidebarOpen"}},[_c('div',{staticClass:"app-list pa-2"},[_c(VList,{attrs:{"rounded":"","dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedPlatform),callback:function ($$v) {_vm.selectedPlatform=$$v},expression:"selectedPlatform"}},_vm._l((_vm.platformList),function(app,i){return _c(VListItem,{key:i,on:{"click":function($event){_vm.isLeftSidebarOpen && _vm.$vuetify.breakpoint.smAndDown ? (_vm.isLeftSidebarOpen = !_vm.isLeftSidebarOpen) : null}}},[_c(VListItemIcon,[_c('img',{attrs:{"height":"24","src":app.logo}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(app.name))])],1)],1)}),1)],1)],1)]),_c('div',{staticClass:"app-config pa-1",style:({
          'margin-left': _vm.isLeftSidebarOpen && _vm.$vuetify.breakpoint.mdAndUp ? '280px' : null,
        })},[(_vm.$vuetify.breakpoint.smAndDown)?_c(VRow,{staticClass:"mt-n1 mb-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLeftSidebarOpen),expression:"!isLeftSidebarOpen"}],on:{"click":function($event){_vm.isLeftSidebarOpen = true}}},[_vm._v(" "+_vm._s(_vm.mdiMenu)+" ")])],1)],1)],1):_vm._e(),(!_vm.loadingPlatform)?_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.selectedPlatform),callback:function ($$v) {_vm.selectedPlatform=$$v},expression:"selectedPlatform"}},_vm._l((_vm.platformList),function(app){return _c(VTabItem,{key:app.name},[(_vm.loadingDeleteToken)?_c(VOverlay,{attrs:{"absolute":"","opacity":"0.3"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),(app.component)?_c(app.component,_vm._b({tag:"component",attrs:{"rounded":""},on:{"refetch":_vm.fetchIntegrationList}},'component',{ platformId: app.id },false)):_c(VCardText,[_vm._v(" Coming Soon ")])],1)}),1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }