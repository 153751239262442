<template>
  <div>
    <v-alert
      v-if="parsedConnectionStatus && !loadingConnectionStatus && parsedConnectionStatus.status === 'Connected'"
      border="left"
      :color="parsedConnectionStatus.status === 'Connected'?'primary':'error'"
      dense
      text
      tile
      :type="parsedConnectionStatus.status === 'Connected'?'success':'error'"
      class="mb-0 text-subtitle-2 py-4"
    >
      <div class="d-flex flex-column flex-md-row align-md-center">
        <span
          v-if="parsedConnectionStatus.status === 'Connected'"
          class="text-caption"
        >Terhubung dengan {{ parsedConnectionStatus.data && parsedConnectionStatus.data.profile.username }}</span>
        <span
          v-else
          class="text-caption"
        >Koneksi {{ parsedConnectionStatus.data && parsedConnectionStatus.data.profile.username }} bermasalah</span>
        <div class="d-flex align-center mt-2 mt-md-0 ml-md-auto">
          <v-btn
            class="ml-md-auto mr-2"
            color="info"
            x-small
            outlined
            @click="checkConnection()"
          >
            Cek Koneksi
          </v-btn>
          <v-btn
            color="error"
            x-small
            text
            @click="deleteTelegram"
          >
            Hapus
          </v-btn>
        </div>
      </div>
    </v-alert>
    <v-alert
      v-else-if="loadingConnectionStatus"
      border="left"
      dense
      text
      tile
      type="info"
      color="info"
      class="mb-0 text-subtitle-2 py-4"
    >
      <div class="d-flex flex-column flex-md-row align-md-center">
        <span
          class="text-caption"
        >Sedang mendapatkan status koneksi {{ connection.connection_name }}</span>
        <v-progress-circular
          indeterminate
          color="info"
          size="12"
          class="ml-4"
        />
      </div>
    </v-alert>
    <v-alert
      v-else
      border="left"
      color="warning"
      dense
      text
      type="warning"
      class="mb-0 text-subtitle-2 py-2 rounded-b-0"
    >
      <div class="d-flex flex-column flex-md-row align-md-center">
        <span
          class="text-caption"
        >Gagal mendapatkan status koneksi {{ connection.connection_name }}</span>
        <div class="d-flex align-center mt-2 mt-md-0 ml-md-auto">
          <v-btn
            class="ml-md-auto mr-2"
            color="info"
            x-small
            outlined
            @click="checkConnection()"
          >
            Cek Koneksi
          </v-btn>
          <v-btn
            class="ml-md-auto mr-2"
            color="info"
            x-small
            outlined
            :loading="loadingReconnect"
            @click="reconnect()"
          >
            Koneksikan Ulang
          </v-btn>
          <v-btn
            class="ml-md-auto mr-2"
            color="warning"
            x-small
            outlined
            @click="$refs.telegramForm.update(connection)"
          >
            Ubah
          </v-btn>
          <v-btn
            color="error"
            x-small
            text
            @click="deleteTelegram"
          >
            Hapus
          </v-btn>
        </div>
      </div>
    </v-alert>
    <telegram-bot-form
      ref="telegramForm"
      @success="(data) => checkConnection(data)"
    />
  </div>
</template>
<script>
import {
  defineComponent, onMounted, watch, ref, inject,
} from '@vue/composition-api'

import Vue from 'vue'
import useIntegration from '@/composables/useIntegration'
import { apolloClient } from '@/vue-apollo'
import { deleteWorkspaceToken } from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import TelegramBotForm from '@/views/integration/telegram-bot/TelegramBotForm.vue'

export default defineComponent({
  components: {
    TelegramBotForm,
  },
  props: {
    connectionData: {
      default: () => {},
      required: true,
      type: Object,
    },
  },
  setup(props, { emit }) {
    const loadingDeleteToken = inject('loadingDeleteToken')
    const connection = ref(props.connectionData)
    const {
      fetchConnectionChekcer, loadingConnectionStatus, connectionStatus, reconnectThirdPartyFunc, loadingReconnect,
    } = useIntegration()
    onMounted(() => {
      fetchConnectionChekcer(1, connection.value.connection_name)
    })
    const parsedConnectionStatus = ref(false)
    watch(connectionStatus, () => {
      parsedConnectionStatus.value = { ...connectionStatus.value, data: connectionStatus.value?.data && JSON.parse(connectionStatus.value.data) }
    })
    const checkConnection = data => {
      if (!data) fetchConnectionChekcer(1, connection.value.connection_name)
      else {
        fetchConnectionChekcer(1, data)
        connection.value = { ...connection.value, connection_name: data }
      }
    }
    const reconnect = () => {
      reconnectThirdPartyFunc({ connection_name: connection.value.connection_name }, () => {
        checkConnection()
      })
    }
    const deleteTelegram = () => {
      Vue.$dialog({
        title: 'Hapus Telegram?',
        body: 'Konfirmasi jika anda ingin menghapus telegram.',
      }).then(confirm => {
        if (confirm) {
          loadingDeleteToken.value = true
          apolloClient.mutate({
            mutation: deleteWorkspaceToken,
            variables: {
              id: connection.value.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            loadingDeleteToken.value = false
            emit('onDeleteSuccess')
            Vue.notify({
              title: 'Sukses',
              text: 'Berhasil menghapus telegram!',
            })
          }).catch(err => {
            loadingDeleteToken.value = false
            errorHandling(err, 'Hapus Integrasi')
          })
        }
      })
    }

    return {
      parsedConnectionStatus,
      loadingConnectionStatus,
      connectionStatus,
      deleteTelegram,
      checkConnection,
      connection,
      reconnect,
      loadingReconnect,
    }
  },
})
</script>
