<template>
  <div class="pa-3">
    <div class="d-flex flex-wrap flex-column flex-md-row justify-space-between">
      <h3>Integrasi Trello</h3>
      <v-btn
        color="#04AB0F"
        small
        class="mt-4 mt-md-0"
        :loading="state.loadingAuthorize.value"
        @click="getUserConsent()"
      >
        <span class="text-white">Tambah Integrasi Trello</span>
      </v-btn>
    </div>
    <v-card
      class="mt-6"
      outlined
    >
      <v-alert
        v-if="!tokenDetail.length && !loadingDetail"
        border="left"
        color="warning"
        dense
        text
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <span class="text-caption">Trello belum terhubung ke Oriens CRM. Ikuti instruksi di bawah untuk menghubungkan.</span>
      </v-alert>
      <div
        v-else-if="loadingDetail"
        class="d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="success"
          class="my-4"
        />
      </div>
      <div v-else>
        <TrelloConnectionCard
          v-for="token in tokenDetail"
          :key="token.id"
          :connection-data="token"
          @onDeleteSuccess="getTokenDetail(8)"
        />
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Trello?</h5>
        <span class="text-subtitle-2">Trello adalah sebuah aplikasi manajemen tugas atau task management. Dengan menggunakan aplikasi tambahan ini, kamu bisa lebih mudah bekerja sama dengan teman setimmu.</span>
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi Trello</h5>
        <span class="text-subtitle-2">1. Klik tombol Tambah Integrasi Trello.</span>
        <span class="text-subtitle-2">2. Login ke Akun Trello Anda.</span>
        <span class="text-subtitle-2">3. Berikan akses akses akun trello anda.</span>
        <span class="text-subtitle-2">4. Selamat! Akun Trello anda sudah terintegrasi dengan Oriens.</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { onMounted, watch, ref } from '@vue/composition-api'
import useIntegration from '@/composables/useIntegration'
import useTrello from '@/composables/useTrello'
import { createFieldMapper } from 'vuex-use-fields'
import TrelloConnectionCard from './component/TrelloConnectionCard.vue'
import errorHandling from '@/utils/errorHandling'

const useFieldTrello = createFieldMapper({ getter: 'trello/getField', setter: 'trello/setField' })

export default {
  components: {
    TrelloConnectionCard,
  },
  setup() {
    const state = {
      ...useFieldTrello(['loadingAuthorize']),
    }
    const {
      tokenDetail, getTokenDetail, loadingDetail, addWorkspaceTokenFunc,
    } = useIntegration()
    // eslint-disable-next-line no-unused-vars
    const {
      getRequestToken, trelloAppName, trelloAuthorizeURL, trelloExpiration, trelloScope,
    } = useTrello()
    onMounted(() => {
      getTokenDetail(8)
    })
    const popup = ref(null)
    const strWindowFeatures = 'toolbar=no, menubar=no, width=640, height=820, top=100, left=100'

    watch(popup, () => {
      if (!popup.value.closed) {
        const checkPopup = setInterval(() => {
          if (popup.value.closed === true) {
            state.loadingAuthorize.value = false
            clearInterval(checkPopup)
          }
        }, 1000)
      } else {
        console.log('tidak ada timeout')
      }
    })

    const handleWindowMessage = event => {
      console.log(event)
      if (typeof event.data === 'string') {
        if (event.data.includes('rejected')) {
          state.loadingAuthorize.value = false
          popup.value.close()
          window.removeEventListener('message', handleWindowMessage)
          errorHandling('Proses integrasi Trello gagal')
        } else {
          addWorkspaceTokenFunc({ access_token: event.data, platform_id: 8 }, () => {
            getTokenDetail(8)
            state.loadingAuthorize.value = false
            popup.value.close()
          })
          window.removeEventListener('message', handleWindowMessage)
        }
      }
    }

    const getUserConsent = () => {
      if (popup.value === null || popup.value.closed) {
        state.loadingAuthorize.value = true
        // eslint-disable-next-line no-restricted-globals
        const returnUrl = `${location.protocol}//${location.host}${location.pathname}${location.search}`
        popup.value = window.open(`${trelloAuthorizeURL}response_type=token&key=2553f5642eae7707cb952781c83aa7a5&return_url=${returnUrl}&callback_method=postMessage&scope=${trelloScope}&expiration=${trelloExpiration}&name=${trelloAppName}`, 'trello', strWindowFeatures)
        popup.value.focus()
        window.addEventListener('message', handleWindowMessage)
        window.addEventListener('unload', event => {
          console.log(event)
        })
      } else {
        popup.value.focus()
      }
    }

    const formTrello = () => {
      getRequestToken(getUserConsent)
    }

    return {
      formTrello,
      tokenDetail,
      getTokenDetail,
      loadingDetail,
      getUserConsent,
      state,
    }
  },
}
</script>

<style>

</style>
