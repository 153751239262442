import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600"},on:{"input":function($event){!$event ? _vm.close() : null}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,[_c('div',{staticClass:"d-flex justify-space-between pa-4"},[_c('span',{staticClass:"text-subtitle-1 font-medium primary--text"},[_vm._v("Telegram Form")]),_c(VIcon,{on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c(VCardText,[_c(VForm,{ref:"telegramForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c(VTextField,{attrs:{"rules":[_vm.required],"label":"No HP","placeholder":"Input No HP","dense":"","outlined":"","hide-details":"auto"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.loadingForm},on:{"click":function($event){return _vm.sendCode(_vm.formData.phone)}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }