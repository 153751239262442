<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    @input="!$event ? close() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-subtitle-1 font-medium primary--text">Telegram Form</span>
        <v-icon
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text>
        <v-form
          ref="telegramForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="formData.phone"
            :rules="[required]"
            label="No HP"
            placeholder="Input No HP"
            dense
            outlined
            hide-details="auto"
          />
        </v-form>
        <div class="d-flex justify-end mt-4">
          <v-btn
            class="mr-2"
            outlined
            @click="reset"
          >
            Reset
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingForm"
            @click="sendCode(formData.phone)"
          >
            Simpan
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required } from '@core/utils/validation'
import useIntegration from '@/composables/useIntegration'
import useTelegram from '@/composables/useTelegram'

export default {
  setup(props, { emit }) {
    const telegramForm = ref()
    const isOpen = ref(false)
    const formData = ref({})
    const loadingSubmit = ref(false)
    const { addWorkspaceTokenFunc, loadingForm } = useIntegration()
    const {
      // telegramCode, loadingTelegramCode,
      sendCode,
      // telegramSignIn, loadingTelegramSignIn,
      signIn,
    } = useTelegram()
    const show = () => {
      isOpen.value = true
    }

    const reset = () => {
      loadingSubmit.value = false
      formData.value = {}

      telegramForm.value.reset()
    }

    const close = () => {
      isOpen.value = false

      reset()
    }

    const submitForm = () => {
      if (telegramForm.value.validate()) {
        loadingForm.value = true
        addWorkspaceTokenFunc({ access_token: formData.value.accessToken, platform_id: 1 }, () => {
          emit('success')
          close()
          loadingForm.value = false
        })
      }
    }

    return {
      telegramForm,
      isOpen,
      loadingSubmit,
      formData,
      loadingForm,

      show,
      close,
      submitForm,
      reset,

      sendCode,
      signIn,

      icons: {
        mdiClose,
      },

      required,
    }
  },
}
</script>

<style>

</style>
