<template>
  <div class="pa-3">
    <div class="d-flex flex-wrap flex-column flex-md-row justify-space-between">
      <h3>Integrasi Shopee</h3>
      <v-btn
        small
        class="mt-4 mt-md-0"
        color="#ff5721"
        :loading="loadingShopeeAuthLink"
        @click="shopeeAuthLink"
      >
        <span class="text-white">Tambah Integrasi Shopee</span>
      </v-btn>
    </div>
    <v-card
      class="mt-6"
      outlined
      :loading="loadingDetail"
    >
      <v-alert
        v-if="!tokenDetail.length"
        border="left"
        color="warning"
        dense
        text
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <span class="text-caption">Shopee belum terhubung ke Oriens CRM. Ikuti instruksi di bawah untuk menghubungkan.</span>
      </v-alert>
      <div
        v-else-if="loadingDetail"
        class="d-flex justify-center"
      >
        <v-progress-circular
          indeterminate
          color="success"
          class="my-4"
        />
      </div>
      <div v-else>
        <ShopeeConnectionCard
          v-for="token in tokenDetail"
          :key="token.id"
          :connection-data="token"
          @onDeleteSuccess="getTokenDetail(4)"
        />
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Apa itu Shopee?</h5>
        <span class="text-subtitle-2">Shopee adalah aplikasi online shop atau marketplace (platform perdagangan elektronik). Dengan menggunakan shopee, kita bisa lebih mudah berbelanja, menjelajah, dan menjual produk serta jasa apa saja dan dimana saja. </span>
      </div>
      <v-divider />
      <div class="pa-3 d-flex flex-column">
        <h5>Cara Integrasi Shopee</h5>
        <span class="text-subtitle-2">1. Pastikan anda sudah punya akun seller Shopee yang siap disambungkan.</span>
        <span class="text-subtitle-2">2. Jika sudah, klik tombol "Tambah integrasi Shopee" di atas.</span>
        <span class="text-subtitle-2">3. Anda akan diarahkan ke popup login, silahkan inputkan email dan password akun Shopee anda.</span>
        <span class="text-subtitle-2">4. Selanjutnya, anda akan diarahkan ke halaman otorisasi, silahkan tekan tombol konfirmasi otorisasi.</span>
        <span class="text-subtitle-2">5. Selamat! Toko anda sudah terintegrasi dengan Oriens.</span>
      </div>
    </v-card>
  </div>
</template>

<script>
import { watch, onMounted } from '@vue/composition-api'
import { useWindowFocus } from '@vueuse/core'
import useIntegration from '@/composables/useIntegration'
import ShopeeConnectionCard from './component/ShopeeConnectionCard.vue'

export default {
  components: {
    ShopeeConnectionCard,
  },
  props: {
    platformId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const windowVisibility = useWindowFocus()
    const {
      loadingShopeeAuthLink, shopeeAuthLink, loadingDetail,
      tokenDetail, getTokenDetail,
    } = useIntegration()

    watch(windowVisibility, value => {
      if (value && loadingShopeeAuthLink.value) {
        emit('refetch')
        loadingShopeeAuthLink.value = false
      }
    })

    onMounted(() => {
      getTokenDetail(+props.platformId)
    })

    return {
      loadingShopeeAuthLink,
      loadingDetail,
      tokenDetail,

      getTokenDetail,
      shopeeAuthLink,
    }
  },
}
</script>

<style>

</style>
