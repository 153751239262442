<template>
  <div>
    <v-card
      :loading="loadingPlatform"
      min-height="80vh"
    >
      <div class="d-flex pa-5">
        <v-navigation-drawer
          v-model="isLeftSidebarOpen"
          width="280"
          touchless
          mobile-breakpoint="sm"
          :temporary="$vuetify.breakpoint.xsOnly"
          absolute
          class="left-sidebar rounded"
        >
          <div class="app-list pa-2">
            <v-list
              rounded
              dense
            >
              <v-list-item-group
                v-model="selectedPlatform"
                color="primary"
                mandatory
              >
                <v-list-item
                  v-for="(app, i) in platformList"
                  :key="i"
                  @click="isLeftSidebarOpen && $vuetify.breakpoint.smAndDown ? (isLeftSidebarOpen = !isLeftSidebarOpen) : null"
                >
                  <v-list-item-icon>
                    <img
                      height="24"
                      :src="app.logo"
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ app.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-navigation-drawer>
        <div
          :style="{
            'margin-left': isLeftSidebarOpen && $vuetify.breakpoint.mdAndUp ? '280px' : null,
          }"
          class="app-config pa-1"
        >
          <v-row
            v-if="$vuetify.breakpoint.smAndDown"
            class="mt-n1 mb-2"
            no-gutters
          >
            <v-col
              cols="1"
            >
              <v-btn
                icon
              >
                <v-icon
                  v-show="!isLeftSidebarOpen"
                  @click="isLeftSidebarOpen = true"
                >
                  {{ mdiMenu }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-tabs-items
            v-if="!loadingPlatform"
            v-model="selectedPlatform"
            touchless
          >
            <v-tab-item
              v-for="app in platformList"
              :key="app.name"
            >
              <v-overlay
                v-if="loadingDeleteToken"
                absolute
                opacity="0.3"
              >
                <v-progress-circular
                  indeterminate
                  size="64"
                />
              </v-overlay>
              <component
                :is="app.component"
                v-if="app.component"
                rounded
                v-bind="{ platformId: app.id }"
                @refetch="fetchIntegrationList"
              />
              <v-card-text v-else>
                Coming Soon
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  ref, onMounted, watchEffect, provide,
} from '@vue/composition-api'
import { useStorage } from '@vueuse/core'
import { mdiMenu } from '@mdi/js'
import { getVuetify } from '@/@core/utils'
import useIntegration from '@/composables/useIntegration'
import TelegramIntegration from './telegram/TelegramIntegration.vue'
import TelegramBotIntegration from './telegram-bot/TelegramBotIntegration.vue'
import ShopeeIntegration from './shopee/ShopeeIntegration.vue'
import TokopediaIntegration from './tokopedia/TokopediaIntegration.vue'
import WhatsappIntegration from './whatsapp/WhatsappIntegration.vue'
import BcaIntegration from './bca/BcaIntegration.vue'
import AccurateIntegration from './accurate/AccurateIntegration.vue'
import TrelloIntegration from './trello/TrelloIntegration.vue'
// import GoogleIntegration from './google/GoogleIntegration.vue'
import GoogleIntegration from './google/GoogleIntegration.vue'

export default {
  components: {
    TelegramIntegration,
    TelegramBotIntegration,
    ShopeeIntegration,
    TokopediaIntegration,
    WhatsappIntegration,
    BcaIntegration,
    AccurateIntegration,
    TrelloIntegration,
    GoogleIntegration,
  },
  setup() {
    const {
      platformList, loadingPlatform,
      fetchPlatformList, fetchIntegrationList,
      loadingDeleteToken,
    } = useIntegration()
    provide('loadingDeleteToken', loadingDeleteToken)
    const selectedPlatform = useStorage('tabIntegration', 0)
    const $vuetify = getVuetify()
    const isLeftSidebarOpen = ref(true)

    watchEffect(() => {
      if ($vuetify.breakpoint.mdAndUp) isLeftSidebarOpen.value = true
      else isLeftSidebarOpen.value = false
    })

    onMounted(() => {
      fetchPlatformList()
    })

    return {
      platformList,
      loadingPlatform,
      fetchIntegrationList,
      selectedPlatform,
      isLeftSidebarOpen,
      mdiMenu,
      loadingDeleteToken,
    }
  },
}
</script>

<style>
.app-list {
  width: 280px;
  border-radius: 6px;
}

.app-config {
  width: 100%;
  border-radius: 0 6px 6px 0;
}
</style>
