<template>
  <div>
    <template
      v-if="connection.status !== 2"
    >
      <v-alert
        border="left"
        color="warning"
        dense
        text
        tile
        type="info"
        class="mb-0 text-subtitle-2 py-4"
      >
        <div class="d-flex flex-column flex-md-row align-md-center">
          <span
            class="text-caption"
          >Menunggu Admin Oriens menambahkan toko {{ connection.connection_name }} ke sistem Oriens</span>
          <div class="d-flex align-center mt-2 mt-md-0 ml-md-auto">
            <v-btn
              color="error"
              x-small
              text
              @click="deleteTokopedia"
            >
              Hapus
            </v-btn>
          </div>
        </div>
      </v-alert>
    </template>
    <template v-else>
      <v-alert
        v-if="parsedConnectionStatus && !loadingConnectionStatus && parsedConnectionStatus.status === 'Connected'"
        border="left"
        :color="parsedConnectionStatus.status === 'Connected'?'primary':'error'"
        dense
        text
        tile
        :type="parsedConnectionStatus.status === 'Connected'?'success':'error'"
        class="mb-0 text-subtitle-2 py-4"
      >
        <div class="d-flex flex-column flex-md-row align-md-center">
          <span
            v-if="parsedConnectionStatus.status === 'Connected'"
            class="text-caption"
          >Terhubung dengan {{ parsedConnectionStatus.data && parsedConnectionStatus.data.shop_name }}</span>
          <span
            v-else
            class="text-caption"
          >Koneksi {{ parsedConnectionStatus.data && parsedConnectionStatus.data.shop_name }} bermasalah</span>
          <div class="d-flex align-center mt-2 mt-md-0 ml-md-auto">
            <v-btn
              class="ml-md-auto mr-2"
              color="info"
              x-small
              outlined
              @click="checkConnection"
            >
              Cek Koneksi
            </v-btn>
            <v-btn
              color="error"
              x-small
              text
              @click="deleteTokopedia"
            >
              Hapus
            </v-btn>
          </div>
        </div>
      </v-alert>
      <v-alert
        v-else-if="loadingConnectionStatus"
        border="left"
        dense
        text
        tile
        type="info"
        color="info"
        class="mb-0 text-subtitle-2 py-4"
      >
        <div class="d-flex flex-column flex-md-row align-md-center">
          <span
            class="text-caption"
          >Sedang mendapatkan status koneksi {{ connectionData.connection_name }}</span>
          <v-progress-circular
            indeterminate
            color="info"
            size="12"
            class="ml-4"
          />
        </div>
      </v-alert>
      <v-alert
        v-else
        border="left"
        color="warning"
        dense
        text
        type="warning"
        class="mb-0 text-subtitle-2 py-2 rounded-b-0"
      >
        <div class="d-flex flex-column flex-md-row align-md-center">
          <span
            class="text-caption"
          >Gagal mendapatkan status koneksi {{ connectionData.connection_name }}</span>
          <div class="d-flex align-center mt-2 mt-md-0 ml-md-auto">
            <v-btn
              class="ml-md-auto mr-2"
              color="info"
              x-small
              outlined
              @click="checkConnection"
            >
              Cek Koneksi
            </v-btn>
            <v-btn
              color="error"
              x-small
              text
              @click="deleteTokopedia"
            >
              Hapus
            </v-btn>
          </div>
        </div>
      </v-alert>
    </template>

    <TokopediaForm ref="tokopediaForm" />
  </div>
</template>
<script>
import {
  computed, defineComponent, onMounted, watch, ref, inject,
} from '@vue/composition-api'

import Vue from 'vue'
import useIntegration from '@/composables/useIntegration'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import { deleteWorkspaceToken } from '@/graphql/mutations'
import TokopediaForm from '@/views/integration/tokopedia/TokopediaForm.vue'

export default defineComponent({
  components: {
    TokopediaForm,
  },
  props: {
    connectionData: {
      default: () => {},
      required: true,
      type: Object,
    },
  },
  setup(props, { emit }) {
    const loadingDeleteToken = inject('loadingDeleteToken')
    const connection = computed(() => props.connectionData)
    const { fetchConnectionChekcer, loadingConnectionStatus, connectionStatus } = useIntegration()
    onMounted(() => {
      fetchConnectionChekcer(2, connection.value.connection_name)
    })
    const parsedConnectionStatus = ref(false)
    watch(connectionStatus, () => {
      parsedConnectionStatus.value = { ...connectionStatus.value, data: connectionStatus.value?.data && JSON.parse(connectionStatus.value.data) }
    })
    const checkConnection = () => {
      fetchConnectionChekcer(2, connection.value.connection_name)
    }
    const deleteTokopedia = () => {
      Vue.$dialog({
        title: 'Hapus Tokopedia?',
        body: 'Konfirmasi jika anda ingin menghapus tokopedia.',
      }).then(confirm => {
        if (confirm) {
          loadingDeleteToken.value = true
          apolloClient.mutate({
            mutation: deleteWorkspaceToken,
            variables: {
              id: connection.value.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            loadingDeleteToken.value = false
            emit('onDeleteSuccess')
            Vue.notify({
              title: 'Sukses',
              text: 'Berhasil menghapus tokopedia!',
            })
          }).catch(err => {
            loadingDeleteToken.value = false
            errorHandling(err, 'Hapus Integrasi')
          })
        }
      })
    }

    return {
      parsedConnectionStatus,
      loadingConnectionStatus,
      connectionStatus,
      deleteTokopedia,
      checkConnection,
      connection,
    }
  },
})
</script>
