<template>
  <v-dialog
    v-model="isOpen"
    max-width="600"
    @input="!$event ? close() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-subtitle-1 font-medium primary--text">Tokopedia Form</span>
        <v-icon
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text>
        <v-form
          ref="tokopediaForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-text-field
            v-model="formData.shopUrl"
            :rules="[required, validateTokpedURL]"
            label="Tautan Toko"
            placeholder="Input tautan toko"
            dense
            outlined
            hide-details="auto"
          />
        </v-form>
        <div class="d-flex justify-end mt-4">
          <v-btn
            class="mr-2"
            outlined
            @click="reset"
          >
            Reset
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingForm"
            @click="submitForm"
          >
            Simpan
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { required, validateTokpedURL } from '@core/utils/validation'
import useIntegration from '@/composables/useIntegration'

export default {
  setup(props, { emit }) {
    const tokopediaForm = ref()
    const isOpen = ref(false)
    const formData = ref({
      id: null,
      shopUrl: '',
    })
    const { addWorkspaceTokenFunc, loadingForm } = useIntegration()
    const show = () => {
      isOpen.value = true
    }

    const reset = () => {
      loadingForm.value = false
      formData.value = {
        shopUrl: '',
        id: null,
      }

      tokopediaForm.value.reset()
    }

    const close = () => {
      isOpen.value = false

      reset()
    }

    const submitForm = () => {
      if (tokopediaForm.value.validate()) {
        loadingForm.value = true
        addWorkspaceTokenFunc({ shop_url: formData.value.shopUrl, platform_id: 2, status: 0 }, () => {
          emit('success')
          close()
          loadingForm.value = true
        })
      }
    }

    return {
      tokopediaForm,
      isOpen,
      loadingForm,
      formData,

      show,
      close,
      submitForm,
      reset,

      icons: {
        mdiClose,
      },

      required,
      validateTokpedURL,
    }
  },
}
</script>

<style>

</style>
